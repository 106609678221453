.faqbg{
    position: absolute;
    right: 0%;
    top: 69%;
    z-index: -1;
}
.wrapper{
    text-align: center;
    color: white;
}
.qList{
    max-width: 95%;
    margin: 0 auto;
}
.faqHead{
    
    margin-bottom: 100px;
}
.faqHead h1{
        font-weight: 800;
        font-size: 70px;
        margin-bottom: 50px;
        
}
.faqHead p{
        font-size: 30px;
        font-weight: 400;
}
.qHead{
    text-align: left;
    color: white;
}
.qHead a{
    color: white;
}
.qList{
    border: 1px solid var(--second);
}
.qItem {
    padding: 20px;
    border-bottom: 1px solid var(--second);
}
.qItem p{
    text-align: left;
    font-weight: 500;
    font-size: 18px;
    line-height: 40px;
}
.qHead{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-weight: bold;
    font-size: 26px;
    margin-bottom: 35px;
}
.qHead button{
    cursor: pointer;
    background-color: transparent;
    color: var(--third);
    font-size: 26px;
    border: 1px solid white;
    display: flex;
    transition:all 0.3s ease;
    padding: 5px;
}
.icon{
    transition:all 0.3s ease;
}
.rotate{
    transform: rotate(45deg);
}

@media only screen and (max-width: 600px) {
    .qHead{
        font-size: 16px;
    }
}