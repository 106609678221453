.Dropdown-option {
    display: flex;
    height: 82px;
    align-items: center;
    border-bottom: 1px solid var(--prime);
}
.Dropdown-option div, .Dropdown-placeholder div{
    display: flex;
    align-items: center;
    font-weight: 900;
    font-size: 30px;
    text-transform: capitalize;
}
.Dropdown-option img, .Dropdown-placeholder img{
    width: 46.83px;
    margin-right: 5.5px;
}
