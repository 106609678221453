/* animation */

@keyframes anvil {
    0% {
      transform: scale(1) translateY(0px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    1% {
      transform: scale(0.96) translateY(10px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    100% {
      transform: scale(1) translateY(0px);
      opacity: 1;
      box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
  }
  .popup-content  {
    border: 0;
    padding: 0;
    -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
    width: 1160px;
    max-width: 100%;
  }
  .popup-wrapper{
    padding:30px 120px;
  }
  .infoHead {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}
.infoTitle {
  font-weight: 800;
  text-transform:uppercase;
  font-size: 55px;
}
  .infoNetworkIcon img{
    width: 130px;
    max-width: 100%;
    opacity: 0.6;
  }

  .invesment-stats {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--second);
    flex-wrap: wrap;
  }

.stats {
  text-align: center;
  flex: 1 1;
  font-weight: 800;
  border-left: 1px solid #C9D8FF;
}
.stats:nth-child(1) {
  border-left: 0px ;
}
.stat-label{
  font-size: 18px;
  border-bottom: 1px solid #C9D8FF;
  border-top: 1px solid #C9D8FF;
}
.stat-value{
  font-size: 23.4px;
}
.stat-label, .stat-value{
    min-height: 80px;
    padding: 0 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.tableHead{
  margin-top: 50px;
  font-weight: 800;
  font-size: 15px;
  margin-bottom: 33px;
}
.tableHead span, .tableRow span{
  min-width: 180px;
  display: inline-block;
  flex-direction: column;
  margin-right: 15px;
}


.tableRow span{
  border-bottom: 0.843464px solid #C9D8FF;
  padding-bottom: 5px;
  margin-bottom: 23px;
  font-weight: 600;
  font-size: 21px;
  height: 30px;
}
.tableRow span:nth-child(1){
  margin-right: 15px;
}
.tableRow{
  flex-wrap: wrap;
  display: flex;
}
.tableAddress{
  display: flex;
  flex-wrap: wrap;
  border-bottom: 0.843464px solid #C9D8FF;
  padding-bottom: 5px;
  margin-bottom: 23px;
  font-weight: 600;
  font-size: 21px;
  flex: 1;
    justify-content: space-between;
}
.tableRow button, .validator-btn-wrap button{
  background-color: var(--second);
  border: 1px solid var(--second);
  color: white;
  padding: 7px 17px;
  margin-left: 7px;
  border-radius: 4.21732px;
  cursor: pointer;
  text-transform: uppercase;
  transition: 0.3s ease;
}
.tableRow button:hover, .validator-btn-wrap button:hover{
  background-color: white;
  color: var(--second);;
}
.validator-btn-wrap {
  margin:  0 auto;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .popup-content{
    overflow: scroll;
    height: 100%;
  }
  .tableHead{
    visibility: hidden;
    margin-top: 10px;
  }
  .tableAddress{
    font-size: 12px;
    align-items: center;
    justify-content: center;
  }
  .tableAddress button{
    margin-top: 10px;
  }
  .popup-wrapper{
    padding: 0;
  }
  .infoTitle {
    font-size: 22px;
  }
}