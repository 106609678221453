.wrapper{
    position: relative;
    background-color: white;
}
.wrapper h1{
    font-weight: 800;
    font-size: 70px;
    text-align: center;
    padding-top: 100px;
}

.row{
    display: flex;
    flex-wrap: wrap;
}
.row .split{
    margin-top: 10px;
    /* flex: 50%; */
}
.row .title{
    font-weight: 800;
    font-size: 30px;    
    margin-bottom: 10px;
    text-transform: capitalize;
}

.row small{
    font-weight: normal;
    font-size: 19px;
    text-transform: capitalize;
    margin-bottom: 30px;
    display: block;
}
.topHorn{
    content: "";
    border-left: 150px solid transparent;
    border-top: 150px solid var(--prime);;
    top: 0;
    right: 0;
    position: absolute;
}
.bottomHorn{
    content: "";
    border-right: 150px solid transparent;
    border-bottom: 150px solid var(--prime);
    bottom: 0;
    left: 0;
    position: absolute;
}

.dropDown{
    border: 2px solid var(--second) !important;
    border-radius: 2px;
    width: 486px;
    height: 82px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.dropdownMenu{
    width: 486px  !important;
}
.arrow{
    border: 2px solid var(--second);;
    border-radius: 2px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
}

.coinInfo{
    display: flex;
    align-items: center;
}
.coinInfo div{
    text-align: center;
    margin-left: 5px;
}
.price{
    font-weight: 800;
    font-size: 30px;
}
.apy{
    font-weight: 800;
    font-size: 20px;
    color: var(--third);
}
.coinInfo img{
    width: 93.66px;
}

.calcNstake{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 100px;
    flex-wrap: wrap;
}
@media only screen and (max-width: 600px) {
    .wrapper h1{
        font-size: 40px;
    }
    .dropDown{
        width: 100%;
    }
}