.slogen{
    text-align: center;
    color: white;
}

.slogen h4{
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 15px;
    color: var(--third);
}
.slogen h1{
    font-weight: 800;
    font-size: 70px;
    margin-bottom: 55px;
}
.slogen p{
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 50px;
}
.slogen p:nth-of-type(2) span{
    font-weight: 700;
    font-family: 'Poppins';
}
.slogen button{
    color: white;
    padding: 21.5px 50.5px;
    border-radius: 2px;
    font-weight: bold;
    font-size: 20.0308px;
    text-transform: uppercase;
    background-color: var(--second);
    cursor: pointer;
    transition: 0.3s ease all;
    border:2px solid transparent
}
.slogen button:hover{
    background-color: white;
    color: var(--third);
}