.incomeCalc{
    display: flex;
    align-content: center;
    justify-content: space-between;
}
.incomeBox{
    flex: 1;
    text-align: center;
    margin-top: 30px;
    max-width: 32%;
}
.incomeWrap{
    border: 1px solid var(--second);
    padding: 40px 19px ;
}
.holding{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 800;
    font-size: 18px;
    flex-wrap: wrap;
}

.divider{
    border: 1px solid #C9D8FF;
    margin: 10px 0;
}

.incomeDuration{
    font-weight: 800;
    font-size: 25px;
    margin-top: 37px;
}