.footerbg{
    position: absolute;
    left: 0%;
    bottom: 0;
    z-index: -1;
    max-width: 100%;
}
.wrapper{
    padding-top: 65px;
    padding-bottom: 100px;
    text-align: center;
    color: white;
}
.wrapper p{
    text-align: center;
}
.icons{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
}
.logo{
    width: 184px;
}
.socialGroup{
    margin-bottom: 41px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    list-style: none;
}
.socialGroup li{
    margin: 0 26px;
    font-size: 30px;
}
.icon{
    color: white;
    cursor: pointer;
    transition: 0.3s ease;
}
.icon:hover{
    color: var(--third);
}
