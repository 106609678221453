.aboutbg{
    position: absolute;
    max-width: 100%;
    left: 0%;
    top: 30%;
    z-index: -1;
}
.wrapper{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: white;
}
.wrapper .divider{
    flex:50%;
    position: relative;
}
.wrapper h1{
    font-weight: 800;
    font-size: 70px;
}
.wrapper a{
    color: white;
}

.box{
    overflow: hidden;
    /* width: 600px; */
}
.data{
    width: 400%;
    position: relative;
    display: flex;
    transition: 0.3s ease all;
}
.slideBar{
    margin: 40px 0;
    content: "";
    height: 2px;
    background-color: var(--second);
    transition: 0.3s ease all;
}
.slideBar::before{
    content: "";
    height: 2px;
    width: 100%;
    z-index: -1;
    position: absolute;
    background: white;
}
.slideControls{
    text-align: center;
    display: flex;
    justify-content: space-between;
    width: 150px;
    margin: 0 auto;
}
.slideControls button{
    cursor: pointer;
    width: 60px;
    height: 60px;
    border: 1px solid var(--second);
    border-radius: 6px;
    color: white;
    transition: 0.3s ease all;
    background-color: transparent;
    font-size: 30px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.slideControls button:hover{
    background-color: var(--second);
    color: var(--third);
}