.network{
    background-color: white;
    width: 301px;
    max-width: 100%;
    height: 349px;
    margin: 0 auto;
    margin-bottom: 15px;
    align-items: center;
    display: flex;
    justify-content: center;
}
.wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 85%;
}
.netowrk img{
    width: 100px;
}
.networkApy{
    font-size: 20px;
    font-weight: 800;
    color: var(--third);
}
.networkName{
    color: var(--primeText);
    font-size: 24.21px;
    font-weight: bold;
}
.networkStatus{
    color: var(--primeText);
    font-weight: 800;
    font-size: 14.31px;
}
.networkStatus span{
    background-color: #E2A74D;
    display: inline-block;
    border-radius: 50%;
    width: 16.5px;
    height: 16.5px;
    vertical-align: middle;
    margin-right: 5px;
}
.networkStatus .active{
    background-color: var(--third);
}
.network button{
    background-color: var(--second);
    cursor: pointer;
    transition: 0.3s ease all;
    color: white;
    padding: 15.5px 60.5px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14.0308px;
}
.network button:hover{
    background-color: var(--third);
}