.stakeIntro{
    padding-top: 65px;
    padding-bottom: 100px;
    text-align: center;
    color: white;
}
.stakeHead{
margin-bottom: 100px;
}
.stakeHead h1{
    font-weight: 800;
    font-size: 70px;
    margin-bottom: 50px;
}
.stakeHead p{
    font-size: 30px;
    font-weight: 400;
}

.networks{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}