.wrapper{
    width: 25%;
    margin: 0 1%;
}
.imgWrap{
    background-color: var(--second);
    width: 148px;
    height: 148px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7.4px;
    margin-bottom: 20px;
}
.imgWrap img{
    width: 91px;
}

.wrapper h3{
    font-weight: 800;
    font-size: 44px;
    margin-bottom: 40px;
}
.wrapper p{
    max-width: 550px;
    font-size: 22px;
}