.rangeWrapper{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 85px;
}

.outputWrapper{
    width: 350px;
    height: 67px;
    border: 1px solid #4267B2;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.thumb{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #00CF84;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: '0px 2px 6px #AAA'
}
.pAmount{
    font-weight: 500;
    font-size: 14.0308px;
}
.amount{
    font-weight: 800;
    font-size: 18px;
}