@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Poppins:wght@400;700&display=swap);
*{
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}
:root{
  --prime: #131227;
  --primeText: #003366;
  --second: #4267B2;
  --third: #00CF84;
}
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  background-color: #131227;
  background-color: var(--prime);
}

.container{
  padding-top: 65px;
  padding-bottom: 100px;
  width: 1161px;
  max-width: 90%;
  margin: 0 auto;
}

.Header_header__1VCKf{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
.Header_logo__3_SJs{
    max-width: 100%;
}

.Header_nav__3yRlw{
    font-weight: bold;
    font-size: 14px;
    color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}
.Header_nav__3yRlw div{
    text-transform: uppercase;
    margin: 5.5px 20px;
    cursor: pointer;
    transition: 0.3s ease all;
}
.Header_nav__3yRlw div:hover{
    color: var(--third);
}
.Header_delegateBtn__oNQmj{
    padding: 5.5px 20px;
    border-radius: 2px;
    background-color: rgb(66 103 178 / 25%);
    border: 1px solid;
}
@media only screen and (max-width: 600px) {
    .Header_nav__3yRlw{
        display: none;
    }
}
.Slogen_slogen__3WpYf{
    text-align: center;
    color: white;
}

.Slogen_slogen__3WpYf h4{
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 15px;
    color: var(--third);
}
.Slogen_slogen__3WpYf h1{
    font-weight: 800;
    font-size: 70px;
    margin-bottom: 55px;
}
.Slogen_slogen__3WpYf p{
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 50px;
}
.Slogen_slogen__3WpYf p:nth-of-type(2) span{
    font-weight: 700;
    font-family: 'Poppins';
}
.Slogen_slogen__3WpYf button{
    color: white;
    padding: 21.5px 50.5px;
    border-radius: 2px;
    font-weight: bold;
    font-size: 20.0308px;
    text-transform: uppercase;
    background-color: var(--second);
    cursor: pointer;
    transition: 0.3s ease all;
    border:2px solid transparent
}
.Slogen_slogen__3WpYf button:hover{
    background-color: white;
    color: var(--third);
}
.Network_network__1kcOe{
    background-color: white;
    width: 301px;
    max-width: 100%;
    height: 349px;
    margin: 0 auto;
    margin-bottom: 15px;
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}
.Network_wrapper__2eTdE{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    height: 85%;
}
.Network_netowrk__1wPRI img{
    width: 100px;
}
.Network_networkApy__1Y1Mw{
    font-size: 20px;
    font-weight: 800;
    color: var(--third);
}
.Network_networkName__Jrvzq{
    color: var(--primeText);
    font-size: 24.21px;
    font-weight: bold;
}
.Network_networkStatus__37Mdj{
    color: var(--primeText);
    font-weight: 800;
    font-size: 14.31px;
}
.Network_networkStatus__37Mdj span{
    background-color: #E2A74D;
    display: inline-block;
    border-radius: 50%;
    width: 16.5px;
    height: 16.5px;
    vertical-align: middle;
    margin-right: 5px;
}
.Network_networkStatus__37Mdj .Network_active__EW9Iv{
    background-color: var(--third);
}
.Network_network__1kcOe button{
    background-color: var(--second);
    cursor: pointer;
    transition: 0.3s ease all;
    color: white;
    padding: 15.5px 60.5px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14.0308px;
}
.Network_network__1kcOe button:hover{
    background-color: var(--third);
}
/* animation */

@-webkit-keyframes anvil {
    0% {
      -webkit-transform: scale(1) translateY(0px);
              transform: scale(1) translateY(0px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    1% {
      -webkit-transform: scale(0.96) translateY(10px);
              transform: scale(0.96) translateY(10px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    100% {
      -webkit-transform: scale(1) translateY(0px);
              transform: scale(1) translateY(0px);
      opacity: 1;
      box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
  }

@keyframes anvil {
    0% {
      -webkit-transform: scale(1) translateY(0px);
              transform: scale(1) translateY(0px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    1% {
      -webkit-transform: scale(0.96) translateY(10px);
              transform: scale(0.96) translateY(10px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    100% {
      -webkit-transform: scale(1) translateY(0px);
              transform: scale(1) translateY(0px);
      opacity: 1;
      box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
  }
  .popup-content  {
    border: 0;
    padding: 0;
    -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
    width: 1160px;
    max-width: 100%;
  }
  .popup-wrapper{
    padding:30px 120px;
  }
  .infoHead {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
.infoTitle {
  font-weight: 800;
  text-transform:uppercase;
  font-size: 55px;
}
  .infoNetworkIcon img{
    width: 130px;
    max-width: 100%;
    opacity: 0.6;
  }

  .invesment-stats {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    border: 1px solid var(--second);
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }

.stats {
  text-align: center;
  -webkit-flex: 1 1;
          flex: 1 1;
  font-weight: 800;
  border-left: 1px solid #C9D8FF;
}
.stats:nth-child(1) {
  border-left: 0px ;
}
.stat-label{
  font-size: 18px;
  border-bottom: 1px solid #C9D8FF;
  border-top: 1px solid #C9D8FF;
}
.stat-value{
  font-size: 23.4px;
}
.stat-label, .stat-value{
    min-height: 80px;
    padding: 0 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.tableHead{
  margin-top: 50px;
  font-weight: 800;
  font-size: 15px;
  margin-bottom: 33px;
}
.tableHead span, .tableRow span{
  min-width: 180px;
  display: inline-block;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-right: 15px;
}


.tableRow span{
  border-bottom: 0.843464px solid #C9D8FF;
  padding-bottom: 5px;
  margin-bottom: 23px;
  font-weight: 600;
  font-size: 21px;
  height: 30px;
}
.tableRow span:nth-child(1){
  margin-right: 15px;
}
.tableRow{
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  display: -webkit-flex;
  display: flex;
}
.tableAddress{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  border-bottom: 0.843464px solid #C9D8FF;
  padding-bottom: 5px;
  margin-bottom: 23px;
  font-weight: 600;
  font-size: 21px;
  -webkit-flex: 1 1;
          flex: 1 1;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
.tableRow button, .validator-btn-wrap button{
  background-color: var(--second);
  border: 1px solid var(--second);
  color: white;
  padding: 7px 17px;
  margin-left: 7px;
  border-radius: 4.21732px;
  cursor: pointer;
  text-transform: uppercase;
  transition: 0.3s ease;
}
.tableRow button:hover, .validator-btn-wrap button:hover{
  background-color: white;
  color: var(--second);;
}
.validator-btn-wrap {
  margin:  0 auto;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .popup-content{
    overflow: scroll;
    height: 100%;
  }
  .tableHead{
    visibility: hidden;
    margin-top: 10px;
  }
  .tableAddress{
    font-size: 12px;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .tableAddress button{
    margin-top: 10px;
  }
  .popup-wrapper{
    padding: 0;
  }
  .infoTitle {
    font-size: 22px;
  }
}
.Stake_stakeIntro__mu7Jf{
    padding-top: 65px;
    padding-bottom: 100px;
    text-align: center;
    color: white;
}
.Stake_stakeHead__11YWN{
margin-bottom: 100px;
}
.Stake_stakeHead__11YWN h1{
    font-weight: 800;
    font-size: 70px;
    margin-bottom: 50px;
}
.Stake_stakeHead__11YWN p{
    font-size: 30px;
    font-weight: 400;
}

.Stake_networks__3wCgQ{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
.Slide_wrapper__1XpiK{
    width: 25%;
    margin: 0 1%;
}
.Slide_imgWrap__D_qiU{
    background-color: var(--second);
    width: 148px;
    height: 148px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    border-radius: 7.4px;
    margin-bottom: 20px;
}
.Slide_imgWrap__D_qiU img{
    width: 91px;
}

.Slide_wrapper__1XpiK h3{
    font-weight: 800;
    font-size: 44px;
    margin-bottom: 40px;
}
.Slide_wrapper__1XpiK p{
    max-width: 550px;
    font-size: 22px;
}
.About_aboutbg__3QRnV{
    position: absolute;
    max-width: 100%;
    left: 0%;
    top: 30%;
    z-index: -1;
}
.About_wrapper__CO56M{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    color: white;
}
.About_wrapper__CO56M .About_divider__2G11L{
    -webkit-flex:50% 1;
            flex:50% 1;
    position: relative;
}
.About_wrapper__CO56M h1{
    font-weight: 800;
    font-size: 70px;
}
.About_wrapper__CO56M a{
    color: white;
}

.About_box__1GFXE{
    overflow: hidden;
    /* width: 600px; */
}
.About_data__2b7jW{
    width: 400%;
    position: relative;
    display: -webkit-flex;
    display: flex;
    transition: 0.3s ease all;
}
.About_slideBar__1KzrA{
    margin: 40px 0;
    content: "";
    height: 2px;
    background-color: var(--second);
    transition: 0.3s ease all;
}
.About_slideBar__1KzrA::before{
    content: "";
    height: 2px;
    width: 100%;
    z-index: -1;
    position: absolute;
    background: white;
}
.About_slideControls__1EuJk{
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 150px;
    margin: 0 auto;
}
.About_slideControls__1EuJk button{
    cursor: pointer;
    width: 60px;
    height: 60px;
    border: 1px solid var(--second);
    border-radius: 6px;
    color: white;
    transition: 0.3s ease all;
    background-color: transparent;
    font-size: 30px;
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.About_slideControls__1EuJk button:hover{
    background-color: var(--second);
    color: var(--third);
}
.Calculator_wrapper__12DS_{
    position: relative;
    background-color: white;
}
.Calculator_wrapper__12DS_ h1{
    font-weight: 800;
    font-size: 70px;
    text-align: center;
    padding-top: 100px;
}

.Calculator_row__2a8lt{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}
.Calculator_row__2a8lt .Calculator_split__1nZIQ{
    margin-top: 10px;
    /* flex: 50%; */
}
.Calculator_row__2a8lt .Calculator_title__3ZEWI{
    font-weight: 800;
    font-size: 30px;    
    margin-bottom: 10px;
    text-transform: capitalize;
}

.Calculator_row__2a8lt small{
    font-weight: normal;
    font-size: 19px;
    text-transform: capitalize;
    margin-bottom: 30px;
    display: block;
}
.Calculator_topHorn__3Bmwo{
    content: "";
    border-left: 150px solid transparent;
    border-top: 150px solid var(--prime);;
    top: 0;
    right: 0;
    position: absolute;
}
.Calculator_bottomHorn__Sj2xH{
    content: "";
    border-right: 150px solid transparent;
    border-bottom: 150px solid var(--prime);
    bottom: 0;
    left: 0;
    position: absolute;
}

.Calculator_dropDown__2IDZU{
    border: 2px solid var(--second) !important;
    border-radius: 2px;
    width: 486px;
    height: 82px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
.Calculator_dropdownMenu__rcmF_{
    width: 486px  !important;
}
.Calculator_arrow__RW09H{
    border: 2px solid var(--second);;
    border-radius: 2px;
    width: 50px;
    height: 50px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: 30px;
}

.Calculator_coinInfo__1EqzB{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}
.Calculator_coinInfo__1EqzB div{
    text-align: center;
    margin-left: 5px;
}
.Calculator_price__3Zc8y{
    font-weight: 800;
    font-size: 30px;
}
.Calculator_apy__3ft6h{
    font-weight: 800;
    font-size: 20px;
    color: var(--third);
}
.Calculator_coinInfo__1EqzB img{
    width: 93.66px;
}

.Calculator_calcNstake__gFsS_{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
    padding-bottom: 100px;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}
@media only screen and (max-width: 600px) {
    .Calculator_wrapper__12DS_ h1{
        font-size: 40px;
    }
    .Calculator_dropDown__2IDZU{
        width: 100%;
    }
}
.Dropdown-option {
    display: -webkit-flex;
    display: flex;
    height: 82px;
    -webkit-align-items: center;
            align-items: center;
    border-bottom: 1px solid var(--prime);
}
.Dropdown-option div, .Dropdown-placeholder div{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    font-weight: 900;
    font-size: 30px;
    text-transform: capitalize;
}
.Dropdown-option img, .Dropdown-placeholder img{
    width: 46.83px;
    margin-right: 5.5px;
}

.RangeInput_rangeWrapper__2h1Ny{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    width: 100%;
    margin-top: 85px;
}

.RangeInput_outputWrapper__shcVe{
    width: 350px;
    height: 67px;
    border: 1px solid #4267B2;
    border-radius: 2px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-around;
            justify-content: space-around;
}
.RangeInput_thumb__vu3cp{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #00CF84;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    box-shadow: '0px 2px 6px #AAA'
}
.RangeInput_pAmount__3F0Rt{
    font-weight: 500;
    font-size: 14.0308px;
}
.RangeInput_amount__Jbxtm{
    font-weight: 800;
    font-size: 18px;
}
.ROIcalc_incomeCalc__1UhGP{
    display: -webkit-flex;
    display: flex;
    -webkit-align-content: center;
            align-content: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
.ROIcalc_incomeBox__3X6xJ{
    -webkit-flex: 1 1;
            flex: 1 1;
    text-align: center;
    margin-top: 30px;
    max-width: 32%;
}
.ROIcalc_incomeWrap__1buyo{
    border: 1px solid var(--second);
    padding: 40px 19px ;
}
.ROIcalc_holding__304t-{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    font-weight: 800;
    font-size: 18px;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.ROIcalc_divider__2RjdO{
    border: 1px solid #C9D8FF;
    margin: 10px 0;
}

.ROIcalc_incomeDuration__1u0C0{
    font-weight: 800;
    font-size: 25px;
    margin-top: 37px;
}

.App_topBg__1RYru{
    position: absolute;
    right: 0%;
    top: 0%;
    z-index: -1;
}
.faq_faqbg__2kE3g{
    position: absolute;
    right: 0%;
    top: 69%;
    z-index: -1;
}
.faq_wrapper__1HFiW{
    text-align: center;
    color: white;
}
.faq_qList__1pCoH{
    max-width: 95%;
    margin: 0 auto;
}
.faq_faqHead__1qKcb{
    
    margin-bottom: 100px;
}
.faq_faqHead__1qKcb h1{
        font-weight: 800;
        font-size: 70px;
        margin-bottom: 50px;
        
}
.faq_faqHead__1qKcb p{
        font-size: 30px;
        font-weight: 400;
}
.faq_qHead__1u0xN{
    text-align: left;
    color: white;
}
.faq_qHead__1u0xN a{
    color: white;
}
.faq_qList__1pCoH{
    border: 1px solid var(--second);
}
.faq_qItem__3Wz8T {
    padding: 20px;
    border-bottom: 1px solid var(--second);
}
.faq_qItem__3Wz8T p{
    text-align: left;
    font-weight: 500;
    font-size: 18px;
    line-height: 40px;
}
.faq_qHead__1u0xN{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    font-weight: bold;
    font-size: 26px;
    margin-bottom: 35px;
}
.faq_qHead__1u0xN button{
    cursor: pointer;
    background-color: transparent;
    color: var(--third);
    font-size: 26px;
    border: 1px solid white;
    display: -webkit-flex;
    display: flex;
    transition:all 0.3s ease;
    padding: 5px;
}
.faq_icon__1sknY{
    transition:all 0.3s ease;
}
.faq_rotate__2YGms{
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

@media only screen and (max-width: 600px) {
    .faq_qHead__1u0xN{
        font-size: 16px;
    }
}
.Footer_footerbg__2rnV8{
    position: absolute;
    left: 0%;
    bottom: 0;
    z-index: -1;
    max-width: 100%;
}
.Footer_wrapper__1UzXs{
    padding-top: 65px;
    padding-bottom: 100px;
    text-align: center;
    color: white;
}
.Footer_wrapper__1UzXs p{
    text-align: center;
}
.Footer_icons__3k_Xg{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    width: 100%;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
.Footer_logo__3ACxa{
    width: 184px;
}
.Footer_socialGroup__247sF{
    margin-bottom: 41px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    list-style: none;
}
.Footer_socialGroup__247sF li{
    margin: 0 26px;
    font-size: 30px;
}
.Footer_icon__ISVxC{
    color: white;
    cursor: pointer;
    transition: 0.3s ease;
}
.Footer_icon__ISVxC:hover{
    color: var(--third);
}

