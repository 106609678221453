.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.logo{
    max-width: 100%;
}

.nav{
    font-weight: bold;
    font-size: 14px;
    color: white;
    display: flex;
    align-items: center;
}
.nav div{
    text-transform: uppercase;
    margin: 5.5px 20px;
    cursor: pointer;
    transition: 0.3s ease all;
}
.nav div:hover{
    color: var(--third);
}
.delegateBtn{
    padding: 5.5px 20px;
    border-radius: 2px;
    background-color: rgb(66 103 178 / 25%);
    border: 1px solid;
}
@media only screen and (max-width: 600px) {
    .nav{
        display: none;
    }
}