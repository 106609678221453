*{
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Poppins:wght@400;700&display=swap');
:root{
  --prime: #131227;
  --primeText: #003366;
  --second: #4267B2;
  --third: #00CF84;
}
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  background-color: var(--prime);
}

.container{
  padding-top: 65px;
  padding-bottom: 100px;
  width: 1161px;
  max-width: 90%;
  margin: 0 auto;
}
